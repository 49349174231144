<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="Add New" subTopic="Town" />
          <CLButton format="primary-btn" v-on:func="showAddModal">
            <plus-icon size="1.5x" class="text-white"></plus-icon>
            Add New
          </CLButton>
          <CLButton class="ml-1" format="secondary-btn" :disabled="isUpdateDisabled" v-on:func="updateTown">
            Update All Changes
          </CLButton>
          <CLButton class="ml-1" format="primary-btn" :disabled="false" v-on:func="updateCityIndex">
            Update City Index
          </CLButton>
          <b-button class="ml-1" variant="light" @click="refreshPage">
            <refresh-cw-icon size="1.5x" class="text-dark"></refresh-cw-icon>
          </b-button>
          <add-town-popup ref="AddTownModal" :zoneOptions="zoneOptions" @success="refreshGrid"
            @error="errorCreateTown" />
        </div>
      </div>
      <div class="row mb-0">
        <town-grid :putRequestArray="putRequestArray" :pgCurrentPage="pgCurrentPage" :pgTotalRows="pgTotalRows"
          :pgPerPage="pgPerPage" :processing="processing" :rowData="rowData" :columnDefs="columnDefs"
          @updateRowArray="setUpdateRowArray" @deleteTown="deleteTown" @gridPaginationEvent="gridPaginationEvent"
          @gridPaginationPerPageChange="updatePgPerPageChanges" @changePaginationPageEvent="changePgCurrentPage"
          @filterDataTableEvent="filterTownData"></town-grid>
      </div>
    </div>

    <b-modal id="bv-update-town-confirm" @ok="updateTownAgree">
      <template #modal-title>
        Update Town Records
      </template>
      <div class="d-block text-left">
        <p>{{ updateTownMessage }}</p>

        <div v-if="affectedPostalCodes.length > 0">
          <p>Affected Postal Codes</p>
          <ul>
            <li v-for="(value, key) in affectedPostalCodes" :key="key">
              {{ value['town_name'] }} : {{ value['postal_codes'] }}
            </li>
          </ul>

          <b-form-checkbox id="force-update-town" name="force-update-town" v-model="forceUpdateTown" value="accepted"
            unchecked-value="not_accepted">
            Force update postal codes
          </b-form-checkbox>

        </div>

      </div>
    </b-modal>

  </div>
</template>
<script>
import { PlusIcon, RefreshCwIcon } from "vue-feather-icons";
import townGrid from "@/components/grids/town-grid";
import { CLHeader, CLButton } from "callia-ui-box";
import addTownModal from "@/components/town/add-town-popup";

export default {
  components: {
    PlusIcon,
    RefreshCwIcon,
    "town-grid": townGrid,
    "add-town-popup": addTownModal,
    CLHeader,
    CLButton,
  },
  data() {
    return {
      pgCurrentPage: 1,
      pgTotalRows: 100,
      pgPerPage: 20,
      putRequestArray: [],
      rowData: [],
      processing: false,
      columnDefs: [],
      zoneOptions: [],
      tblZoneOptions: [],
      zones: {},
      isUpdateDisabled: true,
      isCityUpdateInProgress: false,
      updateTownMessage: '',
      affectedPostalCodes: [],
      forceUpdateTown: 'accepted',
    };
  },
  async created() {
    this.processing = true;
    await this.getAllZone()
    await this.loadZoneCMB()
    await this.getAllTown()
    await this.initializeEditableTable()
    this.processing = false
    this.putRequestArray = []
  },
  methods: {
    async filterTownData(filterData) {
      //check filter data array filterOn empty stage
      this.processing = true;
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/town`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
        params: { filter: filterData }
      });

      this.$store.commit("setTown", response.data.result);
      this.rowData = this.handleTown();
      this.loopRowdata();
      this.pgTotalRows = parseInt(response.data.meta[0]['totalrows'])
      this.pgCurrentPage = 1;
      this.processing = false;
    },
    changePgCurrentPage(currentPage) {
      this.pgCurrentPage = currentPage;
    },
    updatePgPerPageChanges(perPage) {
      this.processing = true;
      this.pgCurrentPage = 1;
      this.pgPerPage = parseInt(perPage);
      this.getAllTown();
      this.processing = false;
    },
    gridPaginationEvent(currentPage) {
      this.getAllTownByPagination(currentPage);
    },
    async refreshPage() {
      this.isUpdateDisabled = true;
      this.processing = true;
      await this.getAllZone();
      await this.loadZoneCMB();
      this.putRequestArray = [];
      this.pgCurrentPage = 1;
      this.pgPerPage = parseInt(this.pgPerPage);
      await this.getAllTown();
      this.processing = false;
      this.updateTownMessage = '';
      this.affectedPostalCodes = [];
    },
    async loadZoneCMB() {
      try {
        const zoneArray = this.$store.getters.zone;
        zoneArray.map(qData => {
          this.zoneOptions.push({ value: qData.zone_id, label: qData.zone_name });
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async initializeEditableTable() {
      /** Initialize DataTable */
      try {
        const displayActionButton = event => {
          return 'Delete';
        }
        this.tblZoneOptions = [];

        this.$store.getters.zone.map(qData => {
          this.tblZoneOptions.push({ value: qData.zone_name, text: qData.zone_name })
        });

        this.columnDefs.push(
          { sortable: true, filter: true, field: 'town_name', headerName: 'Town', editable: true },
          {
            sortable: true,
            filter: true,
            field: 'zone_name',
            headerName: 'Zone',
            editable: true,
            type: 'select',
            selectOptions: this.tblZoneOptions
          },
          {
            sortable: true,
            filter: true,
            field: 'region_name',
            headerName: 'Region',
            editable: false
          },
          {
            sortable: false,
            filter: false,
            field: 'action',
            headerName: 'Action',
            editable: false,
            type: 'link',
            formatter: displayActionButton,
            className: 'bg-secondary'
          }
        );
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async deleteTown(event) {
      try {
        this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete this Town: ${event.town_name}.`, {
          title: 'Delete Town',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
        }).then(async (status) => {
          if (status) {
            const response = await this.axios({
              method: "DELETE",
              url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/town/${event.town_id}`,
              headers: {
                Authorization: `Bearer ${this.$store.getters.idToken}`
              },
              data: {
                'town_name': event.town_name
              }
            });
            this.displayMessage("success", response.data['success']);
            await this.getAllTown();
          }
        })
      } catch (errorResponse) {
        this.errorDeleteTown();
        console.error("Error: ", errorResponse);
      }
    },
    async getAffectedPostalCodes(townIDArray, zoneIDArray, townNameArray) {
      this.affectedPostalCodes = [];
      const response = await this.axios({
        method: "PUT",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/town/affected-resources`,
        data: {
          'townIDArray': townIDArray,
          'townNameArray': townNameArray,
          'zoneIDArray': zoneIDArray
        }
      });
      if (response.status === 200) {
        const { result } = response.data;
        if (result && Array.isArray(result)) {
          this.affectedPostalCodes = result;
        }
      }
    },
    async updateCityIndex() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/search/cities`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        }
      });
      this.displayMessage("success", response.data['success']);
    },
    async updateTown() {
      const totalUpdateRecords = this.putRequestArray.length;
      const [townIDArray, zoneIDArray, townNameArray] = [[], [], []];

      if (totalUpdateRecords == 0) {
        this.displayMessage("warning", "Alert! There is no available changes for update");
      } else {
        let updateCodesString = "";
        let response;
        this.putRequestArray.map(async (qData, qIndex) => {
          updateCodesString += `${qData.town_name}, `;
          townNameArray.push(qData.town_name);
          zoneIDArray.push(qData.zone_id);
          townIDArray.push(qData.town_id);
        })
        this.updateTownMessage = `Please confirm that you want to update all of the changes that you have already done on Town: ${updateCodesString}`;
        await this.getAffectedPostalCodes(townIDArray, zoneIDArray, townNameArray);
        this.$bvModal.show('bv-update-town-confirm');
      }
    },
    async updateTownAgree() {
      const [townIDArray, zoneIDArray, townNameArray] = [[], [], []];
      this.putRequestArray.forEach((qData) => {
        townNameArray.push(qData.town_name)
        zoneIDArray.push(qData.zone_id)
        townIDArray.push(qData.town_id)
      });

      const response = await this.axios({
        method: "PUT",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/town`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
        data: {
          'forceCascadeUpdate': this.forceUpdateTown === 'accepted',
          'townIDArray': townIDArray,
          'townNameArray': townNameArray,
          'zoneIDArray': zoneIDArray
        }
      });
      if (response.status === 200) {
        this.displayMessage("success", response.data['success']);
        await this.refreshPage();
      } else {
        this.displayMessage("danger", response.data['error']);
      }
    },
    async getAllZone() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/zone`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
      });
      this.$store.commit("setZone", response.data.result);
      this.zones = {};
      for (const zone of response.data.result) {
        this.zones[zone.zone_id] = zone;
      }
    },
    async getAllTownByPagination(currentPage) {
      const offset = this.pgPerPage * (currentPage - 1);
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/town`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
        params: { 'offset': offset, 'limit': this.pgPerPage }
      });
      this.$store.commit("setTown", response.data.result);
      this.rowData = this.handleTown();
      this.loopRowdata();
      this.pgTotalRows = parseInt(response.data.meta[0]['totalrows']);
    },
    async getAllTown() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/town`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
        params: { 'offset': 0, 'limit': this.pgPerPage }
      });
      this.pgTotalRows = parseInt(response.data.meta[0]['totalrows']);
      this.$store.commit("setTown", response.data.result);
      this.rowData = this.handleTown();
      this.loopRowdata();
    },
    handleTown() {
      const towns = this.$store.getters.town;
      for (const town of towns) {
        town["region_name"] = this.zones[town.zone_id]["region_name"];
      }
      return towns;
    },
    showAddModal() {
      this.$refs.AddTownModal.show();
    },
    setUpdateRowArray(event) {
      this.isUpdateDisabled = false;
      this.putRequestArray = [];
      event.map(qData => {
        this.putRequestArray.push({
          'town_id': qData.town_id,
          'town_name': qData.town_name,
          'zone_id': qData.zone_id,
          'zone_name': qData.zone_name,
        });
      });
    },
    async refreshGrid(message) {
      await this.getAllTown();
      this.displayMessage("success", message);
    },
    errorCreateTown() {
      this.displayMessage("warning", "Town saving process failed.");
    },
    errorUpdateTown() {
      this.displayMessage("warning", "Town update process failed.");
    },
    errorDeleteTown() {
      this.displayMessage("warning", "Town delete process failed.");
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    },
    loopRowdata() {
      this.rowData.forEach(d => {
        d['$cellStyle'] = {
          'town_name': { backgroundColor: '#e7eef2' },
          'zone_name': { backgroundColor: '#e7eef2' },
          'action': { justifyContent: 'center' }
        };
      });
    }
  }
}
</script>
